@font-face {
	font-family: 'KG Primary Dots Lined';
	src: url('./KGPrimaryDotsLined-webfont.woff2') format('woff2'),
		url('./KGPrimaryDotsLined-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KG Primary Dots';
	src: url('./KGPrimaryDots-webfont.woff2') format('woff2'),
		url('./KGPrimaryDots-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Architects Daughter';
	src: url('./ArchitectsDaughter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Fredoka One';
	src: url('./FredokaOne-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
